var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "loader-1",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        width: "100%",
        height: "100%",
        viewBox: "0 0 50 50",
        "xml:space": "preserve",
      },
    },
    [
      _c(
        "path",
        {
          attrs: {
            d: "M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z",
          },
        },
        [
          _c("animateTransform", {
            attrs: {
              attributeType: "xml",
              attributeName: "transform",
              type: "rotate",
              from: "0 25 25",
              to: "360 25 25",
              dur: "0.6s",
              repeatCount: "indefinite",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }