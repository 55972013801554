var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-auth" }, [
    _c(
      "a",
      { staticClass: "modalcloseButton", on: { click: _vm.goPreviousRoute } },
      [_c("icon", { attrs: { name: "close" } })],
      1
    ),
    _c(
      "section",
      { staticClass: "section section--fullScreen section--flexCenter" },
      [
        _c(
          "div",
          { staticClass: "container container--xxs" },
          [
            _c(
              "div",
              { staticClass: "form__title" },
              [
                _c("icon", {
                  staticClass: "icon-logo",
                  attrs: {
                    name: "logo-icon",
                    width: "100%",
                    height: "52",
                    original: "",
                  },
                }),
                _c("h3", { staticClass: "h3 h3__login" }, [
                  _vm._v("Log in to your account"),
                ]),
              ],
              1
            ),
            _vm.isLoading
              ? _c("UiLoader")
              : _c("ValidationObserver", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var handleSubmit = ref.handleSubmit
                        return [
                          _c(
                            "div",
                            { staticClass: "form form--fullWidth formLayout" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "field",
                                  rules: { required: true, email: true },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "formGroup",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.email,
                                                    expression: "email",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput formInput--fullWidth",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                                attrs: {
                                                  name: "email",
                                                  type: "email",
                                                  "data-vv-delay": "800",
                                                  placeholder: "Email",
                                                },
                                                domProps: { value: _vm.email },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.email =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors.length > 0,
                                                      expression:
                                                        "errors.length > 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput__hint formInput__hint--error",
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "field",
                                  rules: { required: true },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "formGroup",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.password,
                                                    expression: "password",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput formInput--fullWidth",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                                attrs: {
                                                  name: "password",
                                                  type: "password",
                                                  "data-vv-delay": "800",
                                                  placeholder: "Password",
                                                },
                                                domProps: {
                                                  value: _vm.password,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.password =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "forgotPassword",
                                                  attrs: {
                                                    to: {
                                                      name: "ForgotPassword",
                                                    },
                                                  },
                                                },
                                                [_vm._v("Forgot password?")]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors.length > 0,
                                                      expression:
                                                        "errors.length > 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput__hint formInput__hint--error",
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm.loginError
                                ? _c("p", { staticClass: "form__error" }, [
                                    _vm._v(_vm._s(_vm.loginError.toString())),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "form__submit" }, [
                                !_vm.IS_ELITEPAGE
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn--fullWidth btn--primary btn--border",
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.validateFields
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Log in")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn--fullWidth btn--primary",
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.validateFields
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Log in")]
                                    ),
                              ]),
                              _c("form-social-buttons"),
                              _c("hr", { staticClass: "form__divider" }),
                              _c("p", [_vm._v("New to TheCharts?")]),
                              !_vm.IS_ELITEPAGE
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn btn--fullWidth btn--white",
                                      attrs: { to: { name: "Signup" } },
                                    },
                                    [_vm._v("Create Account")]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn btn--fullWidth btn--secondary",
                                      attrs: { to: { name: "Signup" } },
                                    },
                                    [_vm._v("Create Account ")]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }